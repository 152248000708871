import { ContentContainer, PageContainer } from '@dayinsure/components';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { PolicyInfoButtons } from './PolicyInfoButtons';
import { PolicyDocuments } from '../../../components/PolicyDocuments';
import { PolicyInfoPayments } from './PolicyInfoPayments';
import { PolicyInfoCovers } from './PolicyInfoCovers';
import { PolicyInfoDrivers } from './PolicyInfoDrivers';
import { PolicyInfoCar } from './PolicyInfoCar';
import { PolicyInfoCover } from './PolicyInfoCover';
import { PolicyInfoDetails } from './PolicyInfoDetails';
import { usePolicyQuery, useMotorPoliciesSummaryQuery, usePerson } from '../../../hooks';
import { ExcessesCard, Loader, ContactCards } from '../../../components';
import { ConditionsCard } from '../../../components/Quote';
import { FeesAndCharges } from '../../Quote/Review/FeesAndCharges';
import { PendingChangesModal } from './PolicyInfoPendingChanges';
import { PolicyInfoPanels } from './PolicyInfoPanels';
import { hasRACAddOn } from '../../../helpers/policy/hasRACAddOn';
import { PolicyInfoClaims } from '../PolicyInfoClaims';

const testId = 'policy-info';

export const PolicyInfo = () => {
  const { id } = useParams<{ id: string }>();
  const { isGuest, isFullAccount } = usePerson();
  const {
    data: policy,
    refetch,
    isRefetching,
    error,
  } = usePolicyQuery(id, undefined, false, isGuest, isFullAccount, true);
  const policyHolderDriver = policy?.drivers?.find(driver => driver.isPolicyholder);
  const [showModal, setShowModal] = useState(false);
  const cantEdit =
    policy?.state === 'Expired' ||
    policy?.state === 'Cancelled' ||
    policy?.state === 'Voided';
  const { data: motorQuote } = useMotorPoliciesSummaryQuery();
  const policyDetails = motorQuote?.find(mq => mq.id === id);
  const pendingChanges = !!policy?.pendingChanges;

  const handleModalClose = () => {
    setShowModal(false);
  };

  if ((!policy && !error) || (!policyDetails && !error)) {
    return <Loader />;
  }

  return (
    <PageContainer className="font-raleway">
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Policy info
      </h1>
      {policyDetails && policy && (
        <ContentContainer>
          <PolicyInfoPanels pendingChanges={policy?.pendingChanges} testId={testId} />

          <PolicyInfoDetails testId={testId} details={{ ...policyDetails, id }} />
          {policy?.vehicle?.registrationNumber && (
            <PolicyInfoCar testId={testId} vehicle={policy.vehicle} />
          )}
          {policyDetails.startDate && policyDetails.endDate && (
            <PolicyInfoCover
              testId={testId}
              startDate={policyDetails.startDate}
              endDate={policyDetails?.cancelledDate || policyDetails.endDate}
            />
          )}
          {policy.drivers?.length && policy.policyholder && (
            <PolicyInfoDrivers
              testId={testId}
              drivers={policy.drivers}
              policyHolder={policy.policyholder}
            />
          )}
          {policy.cover && (
            <PolicyInfoCovers
              testId={testId}
              cover={policy.cover}
              addons={policy.addOns}
              noClaimsHistory={policyHolderDriver?.noClaimsHistory}
              isAutoRenewalRequested={policy?.isAutoRenewalRequested}
              refetch={refetch}
              isRefetching={isRefetching}
            />
          )}
          {policy?.drivers && (
            <PolicyInfoClaims testId={testId} drivers={policy?.drivers} />
          )}
          {policy.snapshotTransaction && (
            <PolicyInfoPayments
              testId={testId}
              transaction={policy.snapshotTransaction}
              displayMoreinfoButton
            />
          )}
          <ConditionsCard testId={testId} />
          {id && <PolicyDocuments testId={testId} policyId={id} />}
          <FeesAndCharges
            testId={testId}
            feesForDisplay={policy?.applicableFees}
            administrationFee={policy.applicableFees?.administrationFee}
          />
          <ExcessesCard testId={testId} noEdit />
          <ContactCards
            className="my-12"
            testId={testId}
            displayRAC={hasRACAddOn(policy?.addOns)}
          />
          <PolicyInfoButtons
            testId={testId}
            cantEdit={cantEdit}
            plateNo={policy?.vehicle?.registrationNumber || undefined}
            showModal={setShowModal}
            pendingChanges={pendingChanges}
          />
        </ContentContainer>
      )}
      <PendingChangesModal
        isOpen={showModal}
        onClose={handleModalClose}
        testId={testId}
      />
    </PageContainer>
  );
};
