import React, { useMemo } from 'react';
import { Button, Card } from '@dayinsure/components';
import { formatInUKLocalTime, useNavigateWithReferrer } from '@dayinsure/shared';
import { PoliciesPaths } from '../../../routes';
import { MotorClaimDto, PolicyDriverDto } from '../../../api/v1';

type PolicyInfoClaimsProps = {
  drivers?: PolicyDriverDto[] | null;
  testId: string;
};
export const PolicyInfoClaims = ({ testId, drivers }: PolicyInfoClaimsProps) => {
  const navigate = useNavigateWithReferrer();
  const mainDriver = drivers?.find((driver: PolicyDriverDto) => driver.isMainDriver);
  const handleClick = () => {
    navigate(PoliciesPaths.Claims);
  };

  const renderClaims = useMemo(
    () =>
      mainDriver?.previousMotorClaims?.map((claim: MotorClaimDto) => (
        <div key={`${claim.type?.code}${claim.date}`}>
          • {claim.type?.description} {formatInUKLocalTime('dd/MM/yyyy', claim.date)}
        </div>
      )),
    [mainDriver?.previousMotorClaims]
  );
  return (
    <section>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_policy-info-claims`}
      >
        <h2 className="mb-4 font-raleway text-lg">Claims under this policy</h2>
        <Card.CardListItem
          label="Previous claims"
          icon="claims-history"
          testId={`${testId}_cover-type_list-item`}
        >
          {renderClaims}
        </Card.CardListItem>
        <Button
          id={`${testId}_claims_card_moreinfo_button`}
          testId={`${testId}_claims_card_moreinfo_button`}
          text="More info"
          variant="bordered"
          onClick={handleClick}
          classNames="mt-6 text-base hover:bg-cta-secondary-hover px-6 h-14 lg:px-8 lg:h-16 w-full sm:w-fit "
        />
      </Card>
    </section>
  );
};
