import { Card } from '@dayinsure/components';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormTextField } from '../../../../components';
import { allowPhoneCharactersOnly } from '../../../../helpers';
import { usePerson } from '../../../../hooks';
import { UserEmailCard } from '../../Review/CreateAccount/CreateAccountForm/UserEmailCard';
import { QuoteJourneyFormData } from '../../../../types';

type CustomFieldsAddress = {
  email?: string | null;
  mobile?: string | null;
  landline?: string | null;
};

type ContactDetailsProps = {
  testId: string;
  noEmailField?: boolean;
  emailDisabled?: boolean;
  noLandlineField?: boolean;
  customFieldsAddress?: CustomFieldsAddress;
  noHeader?: boolean;
  noEmailAtAll?: boolean;
  noEmailInfoCard?: boolean;
};

export const ContactDetails = ({
  testId,
  noEmailField,
  emailDisabled,
  noLandlineField,
  customFieldsAddress,
  noHeader,
  noEmailAtAll,
    noEmailInfoCard,
}: ContactDetailsProps) => {
  const { isFullAccount, person } = usePerson();
  const { setFieldValue } = useFormikContext<QuoteJourneyFormData>();
  const emailFieldName = customFieldsAddress?.email || 'proposer.email';

  useEffect(() => {
    if (isFullAccount) {
      setFieldValue(emailFieldName, person?.email || '');
    }
  }, [emailFieldName, isFullAccount, person?.email, setFieldValue]);

  return (
    <div data-testid={testId} className="my-8 md:my-16">
      {!noHeader && (
        <h3
          className={clsx('mb-6 font-raleway text-lg font-normal md:mb-12', {
            'md:mb-8': noEmailField,
          })}
          data-testid={`${testId}_title`}
        >
          Your contact details
        </h3>
      )}
      {(noEmailField || noEmailAtAll) && !noEmailInfoCard && (
        <Card classNames="mb-8 md:mb-12">
          Please enter your contact details below, in case we need to get in touch with
          you.
        </Card>
      )}
      {noEmailField ? (
        <UserEmailCard
          className="mb-8 md:mb-12"
          email={person?.email || ''}
          testId={testId}
          noEdit
        />
      ) : (
        !noEmailAtAll && (
          <div className="flex relative flex-col mb-8 md:mb-12">
            <FormTextField
              placeholder="e.g. john.smith@example.com"
              name={emailFieldName}
              id={`${testId}_email-input`}
              testId={`${testId}_email-input`}
              label={{ text: 'Email address', icon: 'email' }}
              disabled={emailDisabled}
            />
          </div>
        )
      )}
      {!noLandlineField && (
        <div className="flex relative flex-col mb-8 md:mb-12">
          <FormTextField
            type="tel"
            placeholder="e.g. 01234567890"
            name={customFieldsAddress?.landline || 'proposer.telephoneNumber.landline'}
            id={`${testId}_phone-landline-input`}
            testId={`${testId}_phone-landline-input`}
            label={{ text: 'Telephone number', icon: 'phone' }}
            onInput={allowPhoneCharactersOnly}
          />
        </div>
      )}
      <div className="flex relative flex-col mb-8 md:mb-12">
        <FormTextField
          type="tel"
          placeholder="e.g. 07712345678"
          name={customFieldsAddress?.mobile || 'proposer.telephoneNumber.mobile'}
          id={`${testId}_phone-input`}
          testId={`${testId}_phone-input`}
          label={{ text: 'Mobile number', icon: 'mobile' }}
          onInput={allowPhoneCharactersOnly}
        />
      </div>
    </div>
  );
};
